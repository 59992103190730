import React from "react"

// @ts-ignore
import BendikImage from '../../../static/Team/bendik-lover.png';

// @ts-ignore
import RuneImage from '../../../static/Team/rune-richardsen.png';

// @ts-ignore
import TomasImage from '../../../static/Team/tomas-narboe.png';

// @ts-ignore
import HaraldImage from '../../../static/Team/harald.png';

const people = [
    {
        name: 'Tomas Nærbøe',
        role: 'PM/UX-designer',
        imageUrl: TomasImage,
    },
    {
        name: 'Bendik Løver',
        role: 'CTO/Systemutvikler',
        imageUrl: BendikImage,
    },
    {
        name: 'Rune Richardsen',
        role: 'Systemutvikler/-Arkitekt',
        imageUrl: RuneImage,
    },
    {
        name: 'Harald Ketilsson',
        role: 'CEO/Rådgiver',
        imageUrl: HaraldImage
    },
];

export default function Team() {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-8 sm:space-y-12">
                    <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Joviale&nbsp;folk</h2>
                        <p className="text-xl text-gray-500">
                            Vi har kontorer i Drammen. Kom gjerne innom for en hyggelig&nbsp;prat.
                        </p>
                    </div>
                    <ul className="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-4">
                        {people.map((person) => (
                            <li key={person.name}>
                                <div className="space-y-4">
                                    <img className="mx-auto h-20 w-20 rounded-full lg:w-32 lg:h-32" src={person.imageUrl} alt="" />
                                    <div className="space-y-2">
                                        <div className="text-xs font-medium lg:text-sm">
                                            <h3>{person.name}</h3>
                                            <p className="text-indigo-600">{person.role}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
