import React from "react";
import { Helmet } from 'react-helmet';

import Contact from "../components/Contact";
import Footer from "../components/Footer";
import SiteNavigation from "../components/SiteHeader/navigation";
import SiteHeader from "../components/SiteHeader/type4";
import Team from "../components/Team";

// @ts-ignore
import convivialLogo from '../../static/convivial-logo.svg'

// @ts-ignore
import HaraldImage from '../../static/Team/harald.png';
import Jobs from "../components/Jobs";
import CTA from "../components/CTA";

export default function About() {
    return (
        <>
            <Helmet>
                <title>Convivial | Om</title>
                <link rel="canonical" href="https://convivial.no/om" />
                <meta name="description" content="Convivial betyr Jovial, som skal gjenspeile hvordan vi skal ha det på jobb, men også hvordan vi fremstår og opptrer ovenfor kundene våre." />
                <script async defer data-domain="convivial.no" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>
            <SiteNavigation bgColor="bg-white" />
            <div className="py-16 bg-gray-50 overflow-hidden">
                <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
                    <div className="text-base max-w-prose mx-auto lg:max-w-none">
                        <h2 className="text-base text-convivial font-semibold tracking-wide uppercase">OM OSS</h2>
                        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Historien til Convivial
                        </p>
                    </div>
                    <div className="relative z-1 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
                        <p className="text-lg text-gray-500">
                            Sommeren 2019 bestemte Harald og Bendik seg for å følge drømmen om å etablere eget selskap, og Convivial AS ble stiftet.
                            I løpet av kort tid etter oppstart var både de første kundene,
                            men også de første egenutviklede tjenestene på plass. Siden da har selskapet hatt sunn vekst av antall ansatte, kunder og tjenestetilbud.
                        </p>
                    </div>
                    <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
                        <div className="relative z-10">
                            <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
                                <h3>Joviale & dyktige</h3>
                                <p>
                                    Convivial betyr Jovial, som skal gjenspeile hvordan vi skal ha det på jobb, men også hvordan vi fremstår og opptrer ovenfor kundene våre.
                                </p>
                                <p>
                                    I Convivial står stolthet for det vi leverer høyt i kurs, og vi mener selv at vi leverer både produkter og service som overgår det vi ofte selv har opplevd i arbeidslivet.
                                    I tillegg til å være eksperter innen utvikling og tekniske tjenester,
                                    har vi både et godt øye for design og vet hva som skaper gode brukeropplevelser for de som skal bruke produktene vi leverer.
                                </p>
                                <p>
                                    I dag er Convivial 4 ansatte som holder til i nyoppussede lokaler på Muusøya 1, like utenfor Drammen sentrum.
                                </p>
                            </div>
                        </div>
                        <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
                            <blockquote className="relative bg-white rounded-lg shadow-lg">
                                <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
                                    <img
                                        src={convivialLogo}
                                        alt="Convivial"
                                        className="h-7"
                                    />
                                    <div className="relative text-lg text-gray-700 font-medium mt-8">
                                        <svg
                                            className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
                                            fill="currentColor"
                                            viewBox="0 0 32 32"
                                            aria-hidden="true"
                                        >
                                            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                        </svg>
                                        <p className="relative">
                                            Convivial betyr Jovial, som skal gjenspeile hvordan vi skal ha det på jobb, men også hvordan vi fremstår og opptrer ovenfor kundene våre.
                                        </p>
                                    </div>
                                </div>
                                <cite className="relative flex items-center sm:items-start bg-gray-400 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
                                    <div className="relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:transform sm:-translate-y-1/2">
                                        <img
                                            className="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-gray-100"
                                            src={HaraldImage}
                                            alt="Harald"
                                        />
                                    </div>
                                    <span className="relative ml-4 font-semibold leading-6 sm:ml-24 sm:pl-1">
                                        <p className="text-white font-semibold sm:inline">Harald Ketilsson,</p>{' '}
                                        <p className="sm:inline text-white text-opacity-80">Daglig leder Convivial</p>
                                    </span>
                                </cite>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>

            <Contact />
            <Team />
            <Jobs />
            <Footer bgColor="bg-gray-50" />
        </>
    )
}
